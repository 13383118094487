.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-container h1 {
    font-size: 5rem;
    margin: 0;
  }
  
  .not-found-container p {
    font-size: 1.5rem;
  }
  