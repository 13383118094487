.contact-section {
  text-align: center;
  color: white;
  /*background: linear-gradient(160deg, #6a82fb, #fc5c7d); /* Adjust the gradient to match your screenshot */
  padding: 4rem 0;
}

.contact-section h1 {
  font-size: 4rem;
  margin-bottom: 2rem;
}

.social-media-links {
  display: inline-flex;
  justify-content: center;
  gap: 2rem; /* Adjust the gap between icons */
}

.icon {
  width: 80px; /* New size for icons */
  height: 80px; /* New size for icons */
  transition: transform 0.3s ease;
  border-radius: 50%; /* Makes the icons circular */
  display: flex; /* Enables centered alignment of the SVG within the anchor tag */
  justify-content: center; /* Centers the SVG horizontally */
  align-items: center; /* Centers the SVG vertically */
  padding: 1rem; /* Padding inside the circle */
}

/* Icon specific backgrounds 
.twitter { background-color: #1DA1F2; }
.discord { background-color: #7289DA; }
.instagram { background-color: #E1306C; }
.linkedin { background-color: #0077B5; }
*/

/* Icon color and interactivity */
.icon svg {
  fill: white;
  transition: transform 0.3s ease, fill 0.3s ease;
}

.icon:hover, .icon:focus {
  transform: scale(1.1); /* Enlarges icons on hover */
  cursor: pointer;
  fill: #ddd; /* Change fill color on hover for visual feedback */
}

/* Add responsive design if needed */
