.related-news {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  padding: 2rem 1rem; /* Add some padding */
  margin-top: 4rem; /* Space below the header */
}

.news-article-link {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits text color from parent */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  border-radius: 15px; /* Rounded corners */
  overflow: hidden; /* Ensures nothing spills outside the border radius */
  margin-bottom: 1rem; /* Space between articles */
  width: 90%; /* Adjust width as necessary */
  max-width: 600px; /* Maximum width */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
  display: flex; /* Flex layout for image and content */
  align-items: center; /* Align children vertically */
  transition: transform 0.3s; /* Smooth transformation on hover */
}

.news-article-link:hover {
  transform: translateY(-5px); /* Slightly raise the card on hover */
}

.news-image {
  width: 120px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  object-fit: cover; /* To keep aspect ratio of the images */
  border-top-left-radius: 15px; /* Rounded corners for the image */
  border-bottom-left-radius: 15px; /* Rounded corners for the image */
}

.news-content {
  padding: 1rem; /* Padding inside the content area */
  flex-grow: 1; /* Allows content to fill the space */
}

.news-title {
  font-size: 1rem; /* Adjust font-size as needed */
  margin: 0 0 0.5rem 0; /* Space below the title */
  font-weight: bold; /* Makes the title bold */
}

.news-time {
  font-size: 0.8rem; /* Adjust font-size as needed */
  color: #666; /* Time stamp color */
}
