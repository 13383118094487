.resume-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .resume-header {
    width: 100%;
    padding: 1rem 0;
    margin-top: 5rem;
  }
  
  .resume-header h1 {
    color: white;
    text-align: center;
    margin: 0;
    font-size: 3rem;
  }
  
  .resume-body {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    color: white;
  }
  
  .resume-experience, .resume-awards {
    flex: 1;
    margin: 0 1rem;
  }
  
  .timeline {
    border-left: 2px solid white;
    position: relative;
  }
  
  .timeline-entry {
    position: relative;
    margin-bottom: 2rem;
    margin-left: 1rem;
  }
  
  .timeline-date {
    position: absolute;
    left: -6rem; /* Adjust based on your specific layout */
    top: 0;
  }
  
  .timeline-content h3 {
    margin: 0;
    font-weight: normal;
  }
  
  .timeline-content p {
    margin: 0;
  }
  
  .resume-awards ul {
    padding-left: 20px; /* Align text properly */
  }
  
  .resume-awards li {
    list-style-type: none; /* Remove bullet points */
    margin-bottom: 1rem;
  }
  
  /* Add responsive design and other specific styling as needed */
  