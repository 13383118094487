.profile-image-container {
  width: 150px; /* Size of the container */
  height: 150px; /* Size of the container */
  border-radius: 50%;
  overflow: hidden; /* Ensures the image stays within the circular border */
  margin-top: 1rem;
  border: 5px solid #fff;
  position: relative;
  z-index: 10;
}

.profile-section {
  margin-top: 2rem;
  text-align: center;
  padding: 4rem 1rem;
  /*background-color: #4b6cb7; */
  color: #fff;
}

.hover-area {
  display: inline-block;
  position: relative;
  width: 100%;
}

.image-container {
  position: relative;
  width: 150px; /* Adjust this to the width of your main image */
  margin: auto;
}

.profile-image {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 1rem;
  border: 5px solid #fff;
  z-index: 10;
  position: relative;
  object-fit: cover; 
}

.small-image-container {
  width: 100px; /* Size of the container */
  height: 100px; /* Size of the container */
  border-radius: 50%;
  overflow: hidden; /* Ensures the image stays within the circular border */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease, left 0.5s ease, right 0.5s ease;
  z-index: 9;
}

.small-image {
  width: 100%; /* Fill the container */
  height: 100%; /* Fill the container */
  object-fit: cover; /* Maintain aspect ratio */
}

/* Adjust positioning for each small image container */
.left-1, .left-2 {
  transform: translate(calc(-50% - 50px), -50%) scale(0);
}

.right-1, .right-2 {
  transform: translate(calc(-50% + 50px), -50%) scale(0);
}

/* Adjustments for hover state */
.hover-area:hover .left-1 {
  transform: translate(calc(-50% - 175px), -50%) scale(1);
}

.hover-area:hover .left-2 {
  transform: translate(calc(-50% - 300px), -50%) scale(1);
}

.hover-area:hover .right-1 {
  transform: translate(calc(-50% + 175px), -50%) scale(1);
}

.hover-area:hover .right-2 {
  transform: translate(calc(-50% + 300px), -50%) scale(1);
}

.line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) scaleY(0);
  transition: transform 0.5s ease;
  background-color: #fff;
  height: 3px;
  width: 0; /* Initially zero width */
  z-index: 8;
}

.hover-area:hover .line.visible {
  transform: translateX(-50%) scaleY(1);
  width: calc(600px + 150px); /* Adjust the width based on image positions */
}

.hover-area:hover .line {
  transform: scaleY(1);
}
