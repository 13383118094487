/* Reset default margin and padding */
html, body, #root {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scroll */
}

body {
  /* Ensures that the body takes the full viewport height */
  min-height: 100vh;
  /* Adjust the background-color to match your design */
  background-color: #6a82fb;
}

/* Apply to the root div of your React app */
#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url('./assets/background.svg'); /* Update this path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Header styles */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.nav-toggle {
  display: none; /* Hide toggle button on large screens */
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.navigation {
  display: flex;
  gap: 1rem;
  list-style: none;
  padding: 0;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-toggle {
    display: block; /* Show toggle button on small screens */
    align-self: center; /* Center toggle button when header is column */
  }

  .navigation {
    display: none; /* Hide by default on small screens */
    flex-direction: column;
    width: 100%; /* Full width */
    position: static;
    align-items: center; /* Center items */
    margin-top: 20px; /* Add some space between toggle and links */
  }

  .navigation.collapsed {
    display: flex; /* Show when not collapsed */
  }
}
