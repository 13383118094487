.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: transparent;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.nav-toggle {
  display: none; /* Hide toggle button on large screens */
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.navigation {
  display: flex;
  gap: 1rem;
  list-style: none;
  padding: 0;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Overlay styles */
.overlay {
  display: none; /* Hide by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85); /* Dark overlay with slight transparency */
  z-index: 500; /* Below the navigation but above other content */
}

/* Show overlay when navigation is open */
.overlay.show {
  display: block;
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-toggle {
    display: block; /* Show toggle button on small screens */
    align-self: center; /* Center toggle button when header is column */
  }

  .navigation {
    display: none; /* Hide by default on small screens */
    flex-direction: column;
    width: 100%; /* Full width */
    position: static;
    align-items: center; /* Center items */
    margin-top: 20px; /* Add some space between toggle and links */
    z-index: 1000; /* Ensure navigation is above the overlay */
  }

  .navigation.collapsed {
    display: flex; /* Show when not collapsed */
  }
}
