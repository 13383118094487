.projects-section {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
   /* background-color: #6a82fb; /* Adjust the color to match the screenshot */
  }
  
  .project-card {
    width: 200px; /* Adjust size as needed */
    height: 100px;
    background-color: #e0eafc; /* Adjust the color to match the screenshot */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .projects-section {
      flex-direction: column;
    }
  }
  